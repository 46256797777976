<template>
  <div class="instructions__data">
    <v-data-table
      class="data__table"
      ref="table"
      :headers="headers"
      :items="productInstructionTypes"
      fixed-header
      hide-default-footer
      disable-pagination
      must-sort
      sort-by="id"
      :mobile-breakpoint="mobileBreakpoint"
    >
      <template v-slot:item.force="{ item }">
        <span
          v-if="
            item.id === BEFORE_CONFIRMING_LOCATION &&
            getBeforeConfirmingLocations.length
          "
        >
          <span class="data__action-hide">
            {{ forceToReadBeforeConfirmingLocations ? 'Always' : 'Once' }}
          </span>
          <span
            class="data__action-button text-switcher"
            @click="switchReadMode(BEFORE_CONFIRMING_LOCATION)"
          >
            {{
              forceToReadBeforeConfirmingLocations
                ? 'Switch to Once'
                : 'Switch to Always'
            }}
          </span>
        </span>
        <span
          v-if="
            item.id === AFTER_STARTING_PARKING &&
            getAfterStartingLocations.length
          "
        >
          <span class="data__action-hide">
            {{ forceToReadAfterStartingLocations ? 'Always' : 'Once' }}
          </span>
          <span
            class="data__action-button text-switcher"
            @click="switchReadMode(AFTER_STARTING_PARKING)"
          >
            {{
              forceToReadAfterStartingLocations
                ? 'Switch to Once'
                : 'Switch to Always'
            }}
          </span>
        </span>
        <span v-if="item.id === DURING_VISIT && getDuringVisitLocations.length">
          <span class="data__action-hide">
            {{ forceToReadDuringVisitLocations ? 'Always' : 'Once' }}
          </span>
          <span
            class="data__action-button text-switcher"
            @click="switchReadMode(DURING_VISIT)"
          >
            {{
              forceToReadDuringVisitLocations
                ? 'Switch to Once'
                : 'Switch to Always'
            }}
          </span>
        </span>
      </template>
      <template v-slot:item.steps="{ item }">
        <span v-if="item.id === BEFORE_CONFIRMING_LOCATION">
          {{ getBeforeConfirmingLocations.length }}
        </span>
        <span v-if="item.id === AFTER_STARTING_PARKING">
          {{ getAfterStartingLocations.length }}
        </span>
        <span v-if="item.id === DURING_VISIT">
          {{ getDuringVisitLocations.length }}
        </span>
      </template>
      <template v-slot:item.id="{ item }">
        <div class="mobile-row__action d-flex justify-end">
          <img
            class="data__action-trigger"
            src="@/assets/button-list-menu.svg"
          />

          <div>
            <v-btn
              v-if="filterProductInstructions(item.id).length"
              class="pkmn-button pkmn-button--no-padding data__action-button add_instruction_btn"
              text
              color="primary"
              @click="$emit('edit', item)"
            >
              <img class="action-image" src="@/assets/button-edit.svg" />
            </v-btn>
            <v-btn
              v-else
              class="pkmn-button pkmn-button--no-padding data__action-button add_instruction_btn"
              text
              color="primary"
              @click="$emit('openAddForm', item)"
            >
              <img class="action-image" src="@/assets/add-button.svg" />
            </v-btn>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import merchantHelpers from '@/helpers/merchant'

const BEFORE_CONFIRMING_LOCATION = 1
const AFTER_STARTING_PARKING = 2
const DURING_VISIT = 3

export default {
  name: 'DataTableInstructions',
  props: {
    locationId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      BEFORE_CONFIRMING_LOCATION,
      AFTER_STARTING_PARKING,
      DURING_VISIT,
      productInstructionTypes: [
        {
          type: 'When user selects a location',
          steps: 0,
          force: true,
          id: BEFORE_CONFIRMING_LOCATION
        },
        {
          type: 'When user confirms purchase',
          steps: 0,
          force: true,
          id: AFTER_STARTING_PARKING
        },
        { type: 'During the visit', steps: 0, force: true, id: DURING_VISIT }
      ],
      headers: [
        { text: 'When is instruction shown', value: 'type' },
        { text: 'Steps', value: 'steps' },
        { text: 'Force to read full screen', value: 'force' },
        { text: '', value: 'id', sortable: false }
      ],
      mobileBreakpoint: 767 // screen width in pixel to switch to mobile view
    }
  },
  computed: {
    getBeforeConfirmingLocations() {
      return this.$store.getters.productInstructions
        .filter(
          (item) => item.instruction_type_id === BEFORE_CONFIRMING_LOCATION
        )
        .map((item) => JSON.parse(JSON.stringify(item)))
    },
    forceToReadBeforeConfirmingLocations() {
      return this.getBeforeConfirmingLocations.every(
        (item) => item.force_user_to_read === true
      )
    },
    getAfterStartingLocations() {
      return this.$store.getters.productInstructions
        .filter((item) => item.instruction_type_id === AFTER_STARTING_PARKING)
        .map((item) => JSON.parse(JSON.stringify(item)))
    },
    forceToReadAfterStartingLocations() {
      return this.getAfterStartingLocations.every(
        (item) => item.force_user_to_read === true
      )
    },
    getDuringVisitLocations() {
      return this.$store.getters.productInstructions
        .filter((item) => item.instruction_type_id === DURING_VISIT)
        .map((item) => JSON.parse(JSON.stringify(item)))
    },
    forceToReadDuringVisitLocations() {
      return this.getDuringVisitLocations.every(
        (item) => item.force_user_to_read === true
      )
    },
    productInstructions() {
      return this.$store.getters.productInstructions
    },
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    }
  },
  methods: {
    filterProductInstructions(instructionTypeId) {
      return this.$store.getters.productInstructions
        .filter((item) => item.instruction_type_id === instructionTypeId)
        .map((item) => JSON.parse(JSON.stringify(item)))
    },
    removeInstruction(item) {
      this.$emit('delete', item)
    },
    async switchReadMode(instructionTypeId) {
      const [firstInstruction] =
        this.filterProductInstructions(instructionTypeId)
      await merchantHelpers.saveProductInstruction(
        this.locationId,
        {
          product_id: 1, // TODO Change as soon as locations are ready
          force_user_to_read: !firstInstruction.force_user_to_read,
          instructions: this.filterProductInstructions(instructionTypeId),
          instruction_type_id: instructionTypeId
        },
        true
      )
    }
  },
  watch: {
    async locationId(newValue) {
      await merchantHelpers.fetchProductInstructions(newValue)
    }
  },
  async created() {
    await merchantHelpers.fetchProductInstructions(this.locationId)
  }
}
</script>
<style lang="scss" scoped>
.action-image {
  width: 32px;
  height: 32px;
}
.text-switcher {
  margin: 0;
  color: #37bd01;
  cursor: pointer;
  font-weight: bold;
}
.instructions__data {
  padding-top: 32px;
}
.add_instruction_btn {
  position: relative;
  left: 15px;
}
</style>
