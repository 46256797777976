<template>
  <v-dialog
    v-model="alwaysOpen"
    :fullscreen="isMobile"
    width="80%"
    max-width="512px"
    @click:outside="close"
  >
    <v-card class="form-dialog" v-show="showForceToRead">
      <h1 class="form-dialog__title">
        Show instructions before each parking ?
      </h1>
      <span class="form-dialog__subtitle">
        Instructions will be always shown full screen for the 1st time parking.
        Do you want to show full screen instruction each time people use your
        product?
      </span>
      <v-row class="form-dialog__button-wrap">
        <v-col cols="6">
          <v-btn
            outlined
            class="pkmn-button pkmn-button--has-border"
            color="primary"
            @click="declineForceToRead"
          >
            No
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            class="pkmn-button pkmn-button--has-border"
            color="primary"
            @click="acceptForceToRead"
          >
            Yes
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-form
      v-model="formValid"
      class="border-form"
      v-show="!showForceToRead"
      ref="form"
    >
      <v-card class="form-dialog">
        <div v-if="!isFirstStage" class="prev-step-btn" @click="prevStage">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.7362 5.54786C12.2048 5.07923 12.2048 4.31943 11.7362 3.8508C11.2675 3.38217 10.5077 3.38217 10.0391 3.8508L2.88692 11.003C2.41829 11.4716 2.41829 12.2314 2.88692 12.7001L10.3354 20.1485C10.804 20.6172 11.5638 20.6172 12.0324 20.1485C12.5011 19.6799 12.5011 18.9201 12.0324 18.4515L6.63322 13.0523L21.7688 13.0523C22.4315 13.0523 22.9688 12.515 22.9688 11.8523C22.9688 11.1895 22.4315 10.6523 21.7688 10.6523L6.63178 10.6523L11.7362 5.54786ZM12.0562 19.5223C12.1317 19.2249 12.053 18.8964 11.8203 18.6636L5.90924 12.7525L5.90888 12.7525L11.8202 18.6639C12.0529 18.8966 12.1315 19.2249 12.0562 19.5223ZM2.86312 11.6295C2.90163 11.4778 2.98025 11.3342 3.09897 11.2154L10.2512 4.06323C10.6026 3.71175 11.1725 3.71175 11.524 4.06323C11.7566 4.2959 11.8353 4.62428 11.7599 4.92165C11.8354 4.6242 11.7568 4.29569 11.524 4.06294C11.1726 3.71146 10.6027 3.71146 10.2513 4.06294L3.09905 11.2151C2.98025 11.3339 2.90161 11.4777 2.86312 11.6295ZM5.90773 10.9523L5.90744 10.9525L21.7687 10.9525C22.2657 10.9525 22.6687 11.3555 22.6687 11.8525C22.6687 11.9292 22.6591 12.0036 22.6411 12.0746C22.6591 12.0035 22.6688 11.929 22.6688 11.8523C22.6688 11.3552 22.2658 10.9523 21.7688 10.9523L5.90773 10.9523Z"
              fill="#B9B9B9"
            />
          </svg>
        </div>
        <div class="step-counter">
          <div class="step-counter__text">
            {{ currentStage }} out of {{ instructions.length }}
          </div>
        </div>
        <v-icon
          class="form-dialog__button form-dialog__button--close"
          @click="close"
        >
          mdi-close
        </v-icon>
        <div
          class="form-dialog__remove-btn"
          v-if="showTrashBtn"
          @click="openRemoveStageDialog"
        >
          <img src="@/assets/trash.svg" alt="" />
        </div>

        <h1 class="form-dialog__title">
          {{ editMode ? 'Edit instruction' : 'Add new instruction' }}
          {{ currentStage }}
        </h1>
        <span class="form-dialog__subtitle">
          {{ $t('text.instruction_description') }}
        </span>
        <v-row style="height: 72px" class="image-row-container">
          <v-col cols="12" sm="3">
            <input
              type="file"
              ref="fileInput"
              style="display: none"
              accept="image/png"
            />
            <div
              class="instruction-image-placeholder"
              :class="{ 'error-border-color': fileError }"
              v-bind:style="{
                backgroundImage: `url(${instructionImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'contain'
              }"
              @click="openImagePicker"
              ref="placeholder"
            ></div>
          </v-col>

          <v-col cols="12" sm="7">
            <span @click="openImagePicker" class="instruction-image-title"
              >Upload optional image</span
            >
            <span @click="openImagePicker" class="instruction-image-des"
              >375x250 px, PNG, 2 Mb</span
            >
            <span v-if="fileError" class="instruction-image-error"
              >File size exceeds 2 MB</span
            >
          </v-col>
          <v-col cols="12" sm="2">
            <v-btn v-if="instructionImage" icon @click="removeImage">
              <img src="@/assets/trash.svg" alt="" />
            </v-btn>
          </v-col>
        </v-row>
        <v-row style="height: 60px" class="instruction__title-row">
          <v-col>
            <div class="form-label">Title</div>
            <v-text-field
              outlined
              solo
              flat
              background-color="#F6F6F6"
              v-model="title"
              dense
              class="form-font instruction__title"
              counter
              max-height="40"
              :rules="[rules.max40, rules.required]"
            >
              <template v-slot:counter>
                <div>
                  <span
                    :class="{
                      'counter-text': title.length <= 40,
                      'counter-text-error': title.length > 40
                    }"
                  >
                    {{ title.length }} / 40
                  </span>
                </div>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row style="padding-top: 15px" class="instruction__textarea-row">
          <v-col>
            <div class="form-label">Message</div>
            <v-textarea
              class="instruction__textarea"
              auto-grow
              rows="1"
              outlined
              :maxlength="300"
              solo
              flat
              v-model="message"
              background-color="#F6F6F6"
              counter
              :rules="[rules.max300, rules.required]"
            >
              <template v-slot:counter>
                <div>
                  <span
                    :class="{
                      'counter-text': message.length <= 300,
                      'counter-text-error': message.length > 300
                    }"
                  >
                    {{ message.length }} / 300
                  </span>
                </div>
              </template>
            </v-textarea>
          </v-col>
        </v-row>
        <v-row style="height: 60px; padding-top: 0px">
          <v-col class="pt-0">
            <div class="form-label">Contact phone number (optional)</div>
            <v-text-field
              outlined
              v-model="contactPhone"
              solo
              flat
              background-color="#F6F6F6"
              dense
              class="form-font instruction__phone"
              @focus="focusPhoneField"
              :rules="[rules.phone]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="form-dialog__button-wrap">
          <v-col cols="6">
            <v-btn
              class="pkmn-button pkmn-button--has-border"
              color="primary"
              @click="submitInstruction"
            >
              Save
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              outlined
              class="pkmn-button pkmn-button--has-border"
              color="primary"
              @click="nextStage"
            >
              {{ nextButtonText }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <form-confirm-unsave
      v-if="showUnsavedChangesDialog"
      flow="product instruction"
      @confirm="confirmCloseModal"
      @close="showUnsavedChangesDialog = false"
    />
    <exit-confirm-dialog
      ref="deleteUserConfirmation"
      warning
      :title="$t('text.remove_instruction', { title: title })"
      :text="$t('text.remove_instruction_text')"
      :cancelText="$t('button.cancel')"
      :confirmText="$t('button.confirm')"
      @confirm="removeInstruction"
    />
  </v-dialog>
</template>
<script>
import rules from '@/helpers/validation'
import merchantHelpers from '@/helpers/merchant'
import FormConfirmUnsave from '@/components/Merchant/Form/FormConfirmUnsave.vue'
import ExitConfirmDialog from '@/components/ExitConfirmDialog.vue'
import { getPhoneCode } from 'libphonenumber-js'

const DEFAULT_INSTRUCTION_DATA = {
  title: '',
  message: '',
  contact_phone_number: '',
  image: null,
  valid: false
}

export default {
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    instructionTypeId: {
      type: Number,
      default: null
    },
    locationId: {
      type: Number,
      default: null
    }
  },
  name: 'FormAddNewInstruction',
  components: {
    FormConfirmUnsave,
    ExitConfirmDialog
  },
  data() {
    return {
      formValid: false,
      showUnsavedChangesDialog: false,
      currentStage: 1,
      instructions: [{ ...DEFAULT_INSTRUCTION_DATA }],
      instructionsCopyJson: '',
      alwaysOpen: true,
      showForceToRead: false,
      fileError: false,
      forceUserToRead: false,
      instructionDisplay: null,
      instructionDisplayOptions: ['On trip start', 'On trip end'],
      forceRead: false,
      formChanged: false,
      rules: {
        ...rules,
        max200: (v) =>
          !v || v.length <= 200 || 'Message cannot exceed 200 characters',
        max300: (v) =>
          !v || v.length <= 300 || 'Message cannot exceed 300 characters',
        max40: (v) =>
          !v || v.length <= 40 || 'Message cannot exceed 40 characters'
      }
    }
  },
  computed: {
    showTrashBtn() {
      return !(this.currentStage >= this.instructions.length && !this.editMode)
    },
    nextButtonText() {
      if (this.currentStage + 1 > this.instructions.length) {
        return 'Add next'
      }

      return this.editMode ? 'Next →' : 'Add next'
    },
    isFirstStage() {
      return this.currentStage === 1
    },
    instructionId() {
      return this.instructions[this.currentStage - 1].id
    },
    instructionImage: {
      get() {
        return this.instructions[this.currentStage - 1].image
      },
      set(value) {
        if (value === null) {
          this.instructions[this.currentStage - 1].delete_image = true
        }
        this.instructions[this.currentStage - 1].image = value
      }
    },
    title: {
      get() {
        return this.instructions[this.currentStage - 1].title
      },
      set(value) {
        this.instructions[this.currentStage - 1].title = value
      }
    },
    message: {
      get() {
        return this.instructions[this.currentStage - 1].message
      },
      set(value) {
        this.instructions[this.currentStage - 1].message = value
      }
    },
    contactPhone: {
      get() {
        return this.instructions[this.currentStage - 1].contact_phone_number
      },
      set(value) {
        this.instructions[this.currentStage - 1].contact_phone_number = value
      }
    },
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    },
    messageRows() {
      const lines = this.message.split('\n')
      return lines.length + 1
    }
  },
  watch: {
    instructions: {
      handler(val) {
        this.formChanged = JSON.stringify(val) !== this.instructionsCopyJson
      },
      deep: true
    },
    formValid(isValid) {
      this.instructions[this.currentStage - 1].valid = isValid
    }
  },
  methods: {
    getCountryCode() {
      return getPhoneCode(this.$store.getters.currentMerchantCountry.code)
    },
    focusPhoneField() {
      if (!this.contactPhone) {
        this.contactPhone = `+${this.getCountryCode()} `
      }
    },
    openRemoveStageDialog() {
      this.$refs.deleteUserConfirmation.isOpening = true
    },
    async removeInstruction() {
      if (this.instructionId) {
        await merchantHelpers.deleteProductInstruction(
          this.locationId,
          this.instructionId,
          this.title
        )
        await this.$store.dispatch(
          'setProductInstructions',
          this.$store.getters.productInstructions.filter(
            (item) => item.id !== this.instructionId
          )
        )
        this.close()
      } else {
        this.instructions.splice(this.currentStage - 1, 1)
        if (!this.isFirstStage) {
          this.prevStage()
        }
        this.$nextTick(() => this.$refs.form.resetValidation())
      }
    },
    filterProductInstructions(instructionTypeId) {
      return this.$store.getters.productInstructions
        .filter((item) => item.instruction_type_id === instructionTypeId)
        .map((item) => JSON.parse(JSON.stringify(item)))
    },
    confirmCloseModal() {
      this.showUnsavedChangesDialog = false
      this.formChanged = false
      this.$emit('close')
    },
    acceptForceToRead() {
      this.forceUserToRead = true
      this.submitInstruction()
    },
    declineForceToRead() {
      this.forceUserToRead = false
      this.submitInstruction()
    },
    nextStage() {
      if (!this.$refs.form.validate() || this.fileError) {
        return
      }
      this.$refs.form.resetValidation()
      this.currentStage += 1
      if (!this.instructions[this.currentStage - 1]) {
        this.instructions.push({ ...DEFAULT_INSTRUCTION_DATA })
      }
    },
    prevStage() {
      this.currentStage -= 1
    },
    close() {
      if (this.formChanged) {
        this.showUnsavedChangesDialog = true
        return
      }
      this.$emit('close')
      this.showForceToRead = false
    },
    openImagePicker() {
      const input = this.$refs.fileInput
      input.click()
      input.addEventListener('change', () => {
        const file = input.files[0]
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          this.instructionImage = reader.result
          const placeholder = this.$refs.placeholder
          placeholder.style.backgroundImage = `url(${this.instructionImage})`
        })
        reader.readAsDataURL(file)
        const fileSize = file.size / 1024 / 1024 // in MiB
        this.fileError = fileSize > 2
      })
    },
    handleFileUpload() {
      this.imageUrl = URL.createObjectURL(this.file)
    },
    autoExpand() {
      const textArea = this.$refs.textarea.$refs.input
      textArea.style.height = 'auto'
      textArea.style.height = textArea.scrollHeight + 'px'
    },
    async submitInstruction() {
      if (!this.$refs.form.validate()) {
        return
      }

      if (!this.showForceToRead && !this.editMode) {
        this.showForceToRead = true
        return
      }

      try {
        await merchantHelpers.saveProductInstruction(
          this.locationId,
          {
            product_id: 1, // TODO Change as soon as locations are ready
            force_user_to_read: this.forceUserToRead,
            instructions: this.instructions.filter((i) => i.valid !== false),
            instruction_type_id: this.instructionTypeId,
            image: this.instructionImage
          },
          this.editMode
        )
        this.formChanged = false
        this.close()
      } catch (error) {
        await this.$store.dispatch('setSystemMessage', error)
      }
    },
    removeImage() {
      this.instructionImage = null // reset instructionImage to empty string
    }
  },
  async created() {
    if (this.editMode) {
      this.instructions = this.filterProductInstructions(
        this.instructionTypeId
      ).map((item) => {
        return {
          force_user_to_read: item.force_user_to_read,
          title: item.title,
          message: item.message,
          contact_phone_number: item.contact_phone_number,
          id: item.id,
          image: item.image_url
        }
      })

      this.forceUserToRead = this.instructions.every(
        (item) => item.force_user_to_read === true
      )
    }
    this.instructionsCopyJson = JSON.stringify(this.instructions)
  },
  destroyed() {
    this.formChanged = false
  }
}
</script>

<style lang="scss" scoped>
.form-dialog__title {
  margin-bottom: 1px;
}
.form-label {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 120%;
  color: rgba(31, 31, 31, 0.5);
  margin-bottom: 2px;
}

.instruction-image-placeholder {
  width: 84px;
  height: 56px;
  background: #f7f7f7;
  border-radius: 4px;
  cursor: pointer;
}

.instruction-image-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #333333;
  display: inline-block;
  margin-top: 8px;
  cursor: pointer;
}

.image-row-container {
  margin-top: 20px !important;
}

.instruction-image-des {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: rgba(31, 31, 31, 0.5);
  cursor: pointer;
}

.instruction-image-error {
  color: #eb5757;
  font-weight: 400;
  font-size: 12px;
  position: relative;
  top: -4px;
}

.counter-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: rgba(31, 31, 31, 0.5);
}

.counter-text-error {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #eb5757;
}

.form-dialog {
  position: relative;
}

.step-counter {
  position: absolute;
  left: 50%;
  top: 22px;
  transform: translateX(-50%);
}

.step-counter__text {
  color: rgba(31, 31, 31, 0.5);
  position: relative;
  top: -5px;
}

.form-dialog__button--close {
  top: 13px;
}

.prev-step-btn {
  position: absolute;
  left: 20px;
  top: 22px;
  cursor: pointer;
}

.form-dialog__remove-btn {
  position: absolute;
  top: 12px;
  right: 65px;
  cursor: pointer;
  img {
    width: 32px;
  }
}
</style>
<style lang="scss">
.border-form {
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
      .v-input--has-state
    )
    > .v-input__control
    > .v-input__slot
    fieldset,
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(
      .v-input--has-state
    ):not(.v-input--is-disabled)
    > .v-input__control
    > .v-input__slot:hover
    fieldset {
    color: #fff;
  }
}

.instruction__title {
  .v-text-field__details {
    margin-bottom: 0 !important;
    padding-left: 0 !important;
    .v-messages__message {
      font-size: 10px !important;
    }
  }
}
.instruction__textarea {
  .v-text-field__details {
    margin-bottom: 0 !important;
    padding-left: 0 !important;
    .v-messages__message {
      font-size: 10px !important;
    }
  }
  .v-input__slot {
    margin-bottom: 3px;
  }
}
.instruction__phone {
  .v-text-field__details {
    padding-left: 0 !important;
    .v-messages__message {
      font-size: 10px !important;
    }
  }
}
.instruction__textarea-row,
.instruction__title-row {
  div.col {
    padding-bottom: 0;
  }
}
.instruction__textarea-row {
  padding-top: 10px !important;
}
</style>
